body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,h1,h2,h3,h4,h5,h6{
  font-family: 'Poppins', sans-serif;
}
code {
  font-family: 'Poppins', sans-serif;

}
.react-tel-input .selected-flag:before{
  /* background: #e2e2e2; */
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
  border-bottom: 2px solid #c2c2c2 !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  color:rgb(161 161 161 / 60%) !important;
  font-size:14px !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root{
  /* margin-top:13px !important; */

}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  color:rgb(161 161 161 / 60%) !important;
  font-size:14px !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 6px 0px !important;
  border-bottom:none;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding:11.5px 14px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding:10.5px 14px !important; 
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  color:rgb(5 4 4 / 60%) !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,.css-bf1e45-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  text-align: left !important;
  font-family: 'Poppins', sans-serif !important;
}
.Inputfield-icons {
  align-self: self-end;
  margin: 7px;
  margin-left: 0px;
  margin-bottom: 4px;
  margin-top: 0px !important;
}
div#droplocation{
  text-align: left !important;
  font-family: 'Poppins', sans-serif !important;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
  color:rgb(161 161 161 / 60%) !important;
  font-size:13px !important;
}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before{
  /* border-bottom:none !important; */
}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after{
  /* border-bottom:none !important; */
}
.Searching-page-wrapper{
  background-image: url('/src/assets/images/land-base.jpg');
  background-repeat:no-repeat;
  background-size: cover;
}
.Serching-page-logowrapper {
    text-align: center;
}
.searching-page-logo-image-class {
  width: 65%;
  margin: auto;
  margin-top: 32px;
}
.searching-page-content-image-class {
  width: 50%;

}
.Serching-page-content-wrapper{
  text-align: center;
  height:500px;
  align-self: center;
  display: flex;
  align-items: center;  
}
  .searching-page-content-dots-wrapper{
  text-align: center;
  }  
.searching-page-content-dots-ul{
    display: flex;
    list-style: none;
    justify-content: center;
    padding-left:0px;
}  
.searching-page-content-dots-li{
  background-color: #8b8b8b;
  border-radius: 100%;
  width:15px;
  height:15px;
  margin:5px;
}
.searching-page-content-dots-li.li-active{
  background-color: #dedede;
}
.seraching-page-content-text{
  color:#ffffff;
  font-size:30px;
  margin:15px;
} 
.overlay-inner::-webkit-scrollbar{
width:8px !important;
}
.overlay-inner::-webkit-scrollbar-track {
  background: #cfcaca;       
}
.overlay-inner::-webkit-scrollbar-thumb {
  background-color: #a8a8fc;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

/* ================Home============================================================================================================================================================================= */
.overlay-inner{
  top: 105px;
  bottom: 70px;
  left: 20px;
  right: 20px;
  z-index: 1;
  position: absolute;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  overflow:auto
}
.Homepage-heading-text-p{
            font-size: 17px;
            font-weight: bold;
            color: #004994;
            margin-bottom:17px;
}
.HomePage-Phone-input-wrapper{
  margin-top: 10px;
  display: inherit;
  align-items: center;
  justify-Content: space-between;
  width: 100%;
  margin: auto; 
}
.HomePage-inline-verify-button-main{
  font-weight: bold !important;
  font-size: 13.9px !important;
  text-transform: capitalize !important;
  color:rgb(0,73,148) !important;
  justify-content:end !important;
}
.Homepage-phoneInput-righticon{
  color:green;
   z-index:9999999;
}
.Validation-error-p{
  color:#d32f2f;
  font-size:0.75rem; 
  margin-top:-6px;
  margin-left:50px;
}
.HomePage-inputfields-wrapper{
 width:100%;
 margin-top: 0px;
}
.HomePage-inputfields-main{
  margin:auto !important;
  text-align:center !important; 
  padding:4px 0px !important;
  width: 100% !important;
}
.Fields-wrapper-Homme .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius:0 !important;
}
.Homepage-textfields-icon{
  color:rgb(22,78,131);
}
.Homepage-submit-button {
  text-align: center;
  margin-top: 0rem;
}
.Homepage-submit-button-text{
  font-size: 15px !important;
  font-weight: bold !important;
}
.submit-button-Homepage-active{
  background-color: rgb(0,73,148) !important;
  size: large !important;
  display: block !important;
  border-radius: 20px !important; 
  width: 100% !important;
  padding: 5px 4px !important;
  color:white !important;
  }
.submit-button-Homepage{
  size: large !important;
  display: block !important;
  border-radius: 20px !important; 
  width: 100% !important;
  padding: 5px 4px !important;
}
.Homepage-textfields-image{
  width:20px;
  height:20px;
}
.Fields-wrapper-Homme .css-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before{
  border-bottom: 2px solid rgb(29,93,160) !important;
}
/* .react-tel-input .form-control:focus{
  box-shadow: none !important;
  border-bottom: 2px solid #c2c2c2 !important;
} */

.react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
  border-color:#ffffff !important;
}
.Loading-mainWhole-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999 !important;
  background: rgba(0,0,0,0.5);
}
.Error-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgb(232 232 232 / 60%);
}
.Error-page-wrapper h1{
color:rgb(29,93,160);
}
.Error-page-wrapper-link {
  text-decoration: none !important;
  color: rgb(29,93,160);
}
.Error-page-wrapper-link {
  text-decoration: none !important;
  color: rgb(29,93,160);
  display: flex;
  justify-content: space-around;
}
/* ======================End=============================================================================================================================================================================================== */

/* ======================Footer=============================================================================================================================================================================================== */

.footer-logo {
  background: url(../images/inner-base.jpg) no-repeat left top;
  height: 400px;
  background-size: 100%;
  font-size: 15px;
  text-align: center;
  font-weight: lighter;
}
.Footer-logo-image{
  margin-top: 15px;
  width: 60%;
}
.Footer-div-bottom-wrappe{
  background: #004995 !important;
  display: block !important;
  width: 130px !important;
  height: 130px !important;
  margin: auto !important;
  margin-top: -100px !important;
  border-radius: 50% !important;
}
.Footer-div-bottom-wrapper-image{
  width: 50% !important;
  margin-left: 35px !important;
  margin-top: 35px !important; 
}
.Footer-div-bottom-wrapper-p{
  color: #004995 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-top: 15px !important;
  text-align: center !important;
}
.Otp-input-wrapper{
  display: block !important;
  margin: auto !important;
}
.Verifty-modal-btn-wrapper-main{
                size: large !important;
                display: block !important;
                border-radius: 25px !important;
                margin: auto !important;
                margin-top: 30px !important;
                height: 50px !important;
                background-color: rgb(0,73,148) !important;
}
.Verifty-modal-btn-wrapper-main-text{
                  font-size: 15px !important;
                  font-weight: bold !important;
}

/* ======================End=============================================================================================================================================================================================== */
/* ================FleetNotAvaible============================================================================================================================================================================= */
.Fleetnotavaible-page-wrapper{
  background-image: url('/src/assets/images/sorry-base.jpg');
  background-repeat:no-repeat;
  background-size: cover;
}
.fleetnotavailable-page-content-wrapper{
  display: flex;
  height:500px;
  text-align: center;
  align-items: center;
}
.fleetnotavailable-page-content-text {
  font-size: 22px;
  color: black;
}
.fleetnotavailable-page-content-wrapper-inner{
  width:90%;
  margin:auto;
}
.fleetnotavailable-page-content-text-two{
  color:rgb(0,73,148) !important;
  text-decoration: underline;
  font-size: 22px;
  text-transform: uppercase;
}
/* ====================End=============================================================================================================================================================================================== */

.react-tel-input .selected-flag:before{
 /* border-radius: 25px 2px 2px 29px !important; */
}

div#droplocation{
padding-top: 5px;
padding-bottom: 8px;
}
.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root{
  padding-left:0px !important;
}
.OR-CodeWrapper{
  text-align: center;
}
.Fields-wrapper-Homme {
  margin-top: 10px;
}

.Homepage-Fields-label {
  font-weight: 700;
  color: #777777;
  margin-bottom: 30px !important;
  font-size: 12px;
  /* padding-left:20px; */
}
.Homepage-Fields-label-phone {
  font-weight: 700;
  color: #777777;
  margin-bottom: 20px !important;
  font-size: 12px;
}
input.form-control {
  padding-bottom: 4px !important;
}
input.form-control:focus{
  outline: none !important;
}
.react-tel-input .form-control:focus{
outline: none !important;
border:none !important;
}
.Important-textfields-fields-sub{
  color:rgb(187,14,8);
  font-size:14px;
}
.react-tel-input {
  font-family: 'Roboto',sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
  margin-top: 10px !important;
}
.inline-verify-button-wrapper{
  margin-top:20px;
}
.react-tel-input .form-control{
  padding: 5.5px 14px 3.5px 60px !important;
}
.HomePage-Phone-input-wrapper-inner-div-one{
  width: 70%;
}