
.overlay {
  position: relative;
  height: 700px;
  width: 400px;
  top: auto;
  left: auto;
  border: 0px solid #ebe9e9;
  border-radius: 0px;
  overflow: hidden;
  margin: 0 auto;
}

.inline {
display: inline-block;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 30px;
}


@media screen and (max-width:600px) {
  .MuiBox-root.css-9g6x6z{
    width:280px !important;
  }
  .overlay{
    width:100% !important;
  }
}